import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  Group,
  Image,
  List,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconNumber1, IconNumber2, IconNumber3 } from '@tabler/icons-react';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import { Trans, useTranslation } from '@app/i18n';
import { IntroSvg } from '@components/Default/Homepage/IntroSvg';

const useStyles = createStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    gridTemplateRows: 'minmax(0, auto) minmax(0,1fr)',
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      gridTemplateColumns: '3fr 2fr',
    },

    [theme.fn.largerThan('md')]: {
      gridTemplateRows: '2fr 3fr',
    },
  },
  headlines: {
    gridColumn: '1/2',
    gridRow: '1/2',
    zIndex: 2,
    [theme.fn.largerThan('sm')]: {
      justifySelf: 'start',
      gridColumn: '1 / 3',
      gridRow: '1 / 2',
    },
    [theme.fn.largerThan('md')]: {
      alignSelf: 'end',
    },
  },
  svg: {
    gridColumn: '1/3',
    gridRow: '1/2',
    zIndex: 1,

    [theme.fn.largerThan('sm')]: {
      marginTop: 50,
      alignSelf: 'end',
      justifySelf: 'end',
      gridRow: '1 / 3',
    },
  },
  introText: {
    gridColumn: '1/3',
    gridRow: '2/3',
    zIndex: 2,

    [theme.fn.largerThan('sm')]: {
      gridColumn: '1/2',
    },

    [theme.fn.largerThan('md')]: {
      gridTemplateRows: '2fr 3fr',
      gridColumn: '1/3',
      gridRow: '2/3',
    },
  },
  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },
  subtitle: {
    fontSize: theme.fontSizes.md,
    marginTop: theme.spacing.md,

    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.xs,
      fontSize: theme.fontSizes.sm,
      hyphens: 'auto',
    },
  },
}));

export const Hero: FunctionComponent = () => {
  const { classes } = useStyles();
  const [t] = useTranslation('page_homepage');

  return (
    <Box>
      <Container size="lg" p={0}>
        <Box className={classes.grid}>
          <Box className={classes.headlines} px="md" mt={30}>
            <Title className={classes.title}>{t('hero.title')}</Title>
            <Text color="dimmed" className={classes.subtitle}>
              {t('hero.subtitle')}
            </Text>
          </Box>
          <IntroSvg className={classes.svg} />
          <Stack spacing="xl" className={classes.introText} mt="xl" px="md">
            <List spacing="sm" size="sm" type="ordered">
              <List.Item
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconNumber1 size={12} />
                  </ThemeIcon>
                }
              >
                <span>
                  <Trans ns="page_homepage" i18nKey="hero.steps.1" />
                </span>
              </List.Item>
              <List.Item
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconNumber2 size={12} />
                  </ThemeIcon>
                }
              >
                <span>
                  <Trans ns="page_homepage" i18nKey="hero.steps.2" />
                </span>
              </List.Item>
              <List.Item
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconNumber3 size={12} />
                  </ThemeIcon>
                }
              >
                <span>
                  <Trans ns="page_homepage" i18nKey="hero.steps.3" />
                </span>
              </List.Item>
            </List>

            <Group>
              <Link href="/account/login" passHref={true}>
                <Button radius="xl" size="md">
                  {t('hero.cta.login')}
                </Button>
              </Link>
              <Link href="/account/register" passHref={true}>
                <Button variant="light" radius="xl" size="md">
                  {t('hero.cta.register')}
                </Button>
              </Link>
            </Group>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};
