import { BookFragment } from '@app/graphql/Fragments/BookFragment.generated';
import { ReadAchievementFragment } from '@graphql/Fragments/ReadAchievement.generated';

export function coverUrlForReadAchievement(readAchievement: ReadAchievementFragment): string | null {
  return coverUrlForBook(readAchievement.book, readAchievement.book.catalogId);
}

export function coverUrlForBook({ isbn }: Pick<BookFragment, 'isbn'>, catalogId: string): string | null {
  // TODO: LibID Un-Hardcoden
  return (
    isbn &&
    catalogId &&
    `https://www2.winbiap.de/coverservices-full/getcover.ashx?x13=${isbn}&catid=${catalogId}&libid=371&country=DE`
  );
}
