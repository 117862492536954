import { AspectRatio, Image } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { coverUrlForBook } from '@utilities/coverUrlForBook';

interface IProps {
  isbn: string;
  catalogId: string;
  title: string;
}

export const HomepageLatestBooksItem: FunctionComponent<IProps> = ({ isbn, catalogId, title }) => {
  const image = coverUrlForBook({ isbn }, catalogId);

  return (
    <AspectRatio className="latestBooks__book" ratio={6 / 9}>
      <Image
        src={image}
        alt={title || 'Buchcover'}
        fit="cover"
        width="100%"
        height="100%"
        imageProps={{
          loading: 'lazy',
        }}
        styles={{
          figure: { height: '100%', flex: 1 },
          imageWrapper: { height: '100%', flex: 1 },
        }}
      />
    </AspectRatio>
  );
};
