import gsap from 'gsap';
import React, { FunctionComponent, useEffect } from 'react';

interface IProps {
  className?: string;
}

export const IntroSvg: FunctionComponent<IProps> = ({ className }) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const t = gsap.timeline();
    t.from('#intro_background_right', {
      xPercent: -100,
    })
      .from(
        '#intro_background_left',
        {
          xPercent: 100,
        },
        '<'
      )
      .from(
        '#intro_foreground',
        {
          xPercent: 100,
        },
        '<+=50%'
      )
      .from('#intro_stars', {
        opacity: 0,
        duration: 0.5,
      })
      .fromTo(
        '#intro_stars > *',
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1.5,
          stagger: {
            each: 0.25,
            from: 'random',
          },
        }
      );
  });

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 1703">
      <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g id="intro" fillRule="nonzero">
          <g id="intro_background" transform="matrix(-1 0 0 1 3600 380)">
            <path
              id="intro_background_right"
              fill="#9DEEFF"
              d="M0 285c40 76 211 148 363 83C599.57 266.84 787.51-21.74 1145 2c512 34 444.34 553.92 678 724 294 214 401 400 415 597H0V285z"
            ></path>
            <path
              id="intro_background_left"
              fill="#93BEFF"
              d="M2098 1323c22.726-243 993.758-525 1502-536v536H2098z"
            ></path>
          </g>
          <g transform="translate(1537)">
            <g id="intro_foreground">
              <g id="intro_books" transform="translate(240.2 630)">
                <g id="Group" transform="translate(719.8)">
                  <path
                    id="Path"
                    fill="#3797DB"
                    d="M22.34 562.47l185.11 74.34L436.59 66.25 280.12 3.41c-15.82-6.35-33.79 1.32-40.14 17.14L22.34 562.47z"
                  ></path>
                  <g id="Rectangle">
                    <path
                      fill="#D1EAF2"
                      d="M41.892 28.502h359.96v506.1H41.892z"
                      transform="rotate(-169.95 223.719 302.552)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M176.82 81.855h160.74v3.61H176.82z"
                      transform="rotate(-169.95 259.036 104.66)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M144.642 72.838h228.28v3.61h-228.28z"
                      transform="rotate(-169.95 260.629 95.643)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M112.089 119.161h276.96v3.61h-276.96z"
                      transform="rotate(-169.95 252.416 141.966)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M109.055 136.252h276.96v3.61h-276.96z"
                      transform="rotate(-169.95 249.382 159.057)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M102.997 170.434h276.96v3.61h-276.96z"
                      transform="rotate(-169.95 243.323 193.24)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M99.963 187.525h276.96v3.61H99.963z"
                      transform="rotate(-169.95 240.29 210.33)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M96.928 204.616h276.96v3.61H96.928z"
                      transform="rotate(-169.95 237.255 227.421)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M83.602 279.784h276.96v3.61H83.602z"
                      transform="rotate(-169.95 223.928 302.59)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M80.456 297.504h276.96v3.61H80.456z"
                      transform="rotate(-169.95 220.782 320.309)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M77.332 315.216h276.96v3.61H77.332z"
                      transform="rotate(-169.95 217.658 338.02)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M74.176 332.946h276.96v3.61H74.176z"
                      transform="rotate(-169.95 214.502 355.75)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M71.04 350.665H348v3.61H71.04z"
                      transform="rotate(-169.95 211.366 373.47)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M67.884 368.395h276.96v3.61H67.884z"
                      transform="rotate(-169.95 208.21 391.2)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M64.738 386.115h276.96v3.61H64.738z"
                      transform="rotate(-169.95 205.064 408.92)"
                    ></path>
                  </g>
                  <g id="Rectangle">
                    <path
                      fill="#E1F9FF"
                      d="M58.528 38.02h359.96v506.1H58.528z"
                      transform="rotate(-165.26 245.948 305.359)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M209.521 94.924h160.74v3.61h-160.74z"
                      transform="rotate(-165.26 297.331 111.018)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M178.076 86.08h228.28v3.61h-228.28z"
                      transform="rotate(-165.26 299.656 102.174)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M141.756 131.573h276.96v3.61h-276.96z"
                      transform="rotate(-165.26 287.676 147.666)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M137.343 148.36h276.96v3.61h-276.96z"
                      transform="rotate(-165.26 283.263 164.453)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M128.513 181.924h276.96v3.61h-276.96z"
                      transform="rotate(-165.26 274.433 198.017)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M124.09 198.698h276.96v3.61H124.09z"
                      transform="rotate(-165.26 270.01 214.792)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M119.674 215.495h276.96v3.61h-276.96z"
                      transform="rotate(-165.26 265.594 231.589)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M100.239 289.319h276.96v3.61h-276.96z"
                      transform="rotate(-165.26 246.16 305.412)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M95.655 306.727h276.96v3.61H95.655z"
                      transform="rotate(-165.26 241.576 322.82)"
                    ></path>
                    <path
                      fill="#7772D6"
                      d="M91.074 324.125h276.96v3.61H91.074z"
                      transform="rotate(-165.26 236.995 340.218)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M86.481 341.533h276.96v3.61H86.481z"
                      transform="rotate(-165.26 232.401 357.626)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M81.91 358.931h276.96v3.61H81.91z"
                      transform="rotate(-165.26 227.83 375.025)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M77.309 376.349h276.96v3.61H77.309z"
                      transform="rotate(-165.26 223.229 392.443)"
                    ></path>
                    <path
                      fill="#55769F"
                      d="M72.745 393.75h276.96v3.61H72.745z"
                      transform="rotate(-165.26 218.665 409.843)"
                    ></path>
                  </g>
                  <path
                    id="Path"
                    fill="#62BDF3"
                    d="M92.57 590.68l372.67 149.67L682.6 199.13 369.44 5.95C351.84-4.91 328.7 2.71 321 21.9L92.57 590.68z"
                  ></path>
                </g>
                <g id="Path">
                  <path
                    fill="#9ED16F"
                    d="M283.11 110.88l67.52-19.78C362.52 41.96 369.65.11 369.65.11 288.18 3.4 225.48 28.33 177.26 63.77l-9.98 80.25-21.54-53.81C31.89 198.78 18.57 366.39 18.57 366.39s229.15-89.66 272.82-126.61c16.86-14.27 32.37-53.25 45.05-95.73-22.8-12.28-53.35-33.18-53.35-33.18l.02.01z"
                    transform="translate(977.8 423)"
                  ></path>
                  <path
                    fill="#96A65F"
                    d="M369.65.11S101.49 210.46 18.57 366.4c0 0 229.15-89.66 272.82-126.61 16.86-14.27 32.37-53.25 45.05-95.73-22.8-12.28-53.35-33.18-53.35-33.18l67.52-19.78C362.5 41.96 369.63.11 369.63.11h.02z"
                    transform="translate(977.8 423)"
                  ></path>
                  <path
                    fill="#7772D6"
                    d="M415.7 206.57c-47.6-13.69-92.39-15.74-133.68-10.15-7.71 17.71-17.37 38.7-17.37 38.7l-10.82-33.62C99.08 236.32.43 373.35.43 373.35c103.78 31.6 183.5 26.34 243.99 4.44.41-12.91.74-32.09.74-32.09l18.4 24.35c28.39-12.74 52.02-29.14 71.53-46.62-9.67-12.6-20.19-27.15-20.19-27.15l38.06 9.71c45.98-48.84 62.74-99.42 62.74-99.42z"
                    transform="translate(977.8 423)"
                  ></path>
                  <path
                    fill="#45258F"
                    d="M244.42 377.79c.41-12.91.74-32.09.74-32.09l18.4 24.35c28.39-12.74 52.02-29.14 71.53-46.62-9.67-12.6-20.19-27.15-20.19-27.15l38.06 9.71c45.98-48.84 62.74-99.42 62.74-99.42C231.99 234.68.44 373.35.44 373.35c103.78 31.6 183.5 26.34 243.99 4.44h-.01z"
                    transform="translate(977.8 423)"
                  ></path>
                </g>
                <g id="Path">
                  <path
                    fill="#7772D6"
                    d="M35.92 5.11H10.44C14.61 2.26 19.82.55 25.5.55h638.43c33.4 0 60.48 24 60.48 53.61v34.05c0 29.61-27.08 53.61-60.48 53.61l-631.29-.19L35.93 5.1l-.01.01z"
                    transform="translate(186.8 214)"
                  ></path>
                  <path
                    fill="#D1EAF2"
                    d="M670.85 88.22V54.17c0-29.61-27.08-53.61-60.48-53.61h13.71c33.4 0 60.48 24 60.48 53.61v34.05c0 29.61-27.08 53.61-60.48 53.61h-13.53c33.32-.09 60.3-24.06 60.3-53.61zm-149.52 0V54.17c0-29.61-27.08-53.61-60.48-53.61h13.71c33.4 0 60.48 24 60.48 53.61v34.05c0 29.61-27.08 53.61-60.48 53.61h-13.53c33.32-.09 60.3-24.06 60.3-53.61z"
                    transform="translate(186.8 214)"
                  ></path>
                  <path
                    fill="#45258F"
                    d="M398.37.68c31.94 0 57.84 22.95 57.84 51.27v38.48c0 28.32-25.89 51.27-57.84 51.27H.93v-8.61h1.64l101.04-8.48 20.42-71.02S84.61 14.35 82.04 13.33C79.47 12.32 2.57 9.25 2.57 9.25H.93V.67h397.44v.01z"
                    transform="translate(186.8 214)"
                  ></path>
                  <path
                    fill="#D1EAF2"
                    d="M26.71 70.94C26.71 29.42 2.5 9.29 2.5 9.29h387.73c29.34 0 53.12 21.08 53.12 47.09V86c0 26.01-23.79 47.09-53.12 47.09H2.5s24.21-9.88 24.21-62.16v.01z"
                    transform="translate(186.8 214)"
                  ></path>
                  <path
                    fill="#DAEEF7"
                    d="M443.36 56.38V86c0 3.9-.54 7.67-1.55 11.29-13.08-28.36-38.14-55.68-85.85-64.46C244.57 12.32 2.51 9.28 2.51 9.28h387.73c29.34 0 53.12 21.08 53.12 47.09v.01z"
                    style={{ mixBlendMode: 'multiply' }}
                    transform="translate(186.8 214)"
                  ></path>
                </g>
                <g id="Path">
                  <path
                    fill="#45258F"
                    d="M40.43 6.49H11.59A28.153 28.153 0 0128.63.78h722.58c37.81 0 68.45 30.04 68.45 67.11v42.62c0 37.06-30.65 67.11-68.45 67.11l-714.49-.24L40.44 6.49h-.01z"
                    transform="translate(192.8 495)"
                  ></path>
                  <path
                    fill="#2D185D"
                    d="M450.66.94c36.15 0 65.46 28.73 65.46 64.17v48.17c0 35.44-29.31 64.17-65.46 64.17H.84v-10.78H2.7l114.36-10.62 23.11-88.89S95.56 18.04 92.65 16.77C89.74 15.5 2.7 11.66 2.7 11.66l-1.86-.02V.92h449.82v.02z"
                    transform="translate(192.8 495)"
                  ></path>
                  <path
                    fill="#D1EAF2"
                    d="M30.01 88.89c0-51.97-27.4-77.17-27.4-77.17h438.83c33.21 0 60.13 26.39 60.13 58.95v37.08c0 32.55-26.92 58.94-60.13 58.94H2.61s27.4-12.36 27.4-77.8z"
                    transform="translate(192.8 495)"
                  ></path>
                  <path
                    fill="#DAEEF7"
                    d="M501.57 70.66v37.08c0 4.87-.62 9.61-1.75 14.14-14.8-35.5-43.17-69.69-97.17-80.69C276.58 15.52 2.61 11.72 2.61 11.72h438.83c33.21 0 60.13 26.39 60.13 58.95v-.01z"
                    style={{ mixBlendMode: 'multiply' }}
                    transform="translate(192.8 495)"
                  ></path>
                </g>
                <g id="Path">
                  <path
                    fill="#9ED16F"
                    d="M780.48 5h28.85C804.61 2.17 798.7.47 792.28.47H69.42C31.6.47.94 24.31.94 53.72v33.82c0 29.41 30.66 53.25 68.48 53.25l714.77-.19L780.47 5h.01z"
                    transform="translate(255.8 355)"
                  ></path>
                  <path
                    fill="#96A65F"
                    d="M370.09.59c-36.17 0-65.49 22.8-65.49 50.92v38.22c0 28.12 29.32 50.92 65.49 50.92h450v-8.55h-1.86l-114.4-8.42-23.12-70.54s44.63-38.98 47.54-39.98c2.91-1.01 89.99-4.06 89.99-4.06l1.86-.02V.58h-450l-.01.01z"
                    transform="translate(255.8 355)"
                  ></path>
                  <path
                    fill="#D1EAF2"
                    d="M790.91 70.38c0-41.24 27.41-61.23 27.41-61.23h-439c-33.22 0-60.15 20.94-60.15 46.77v29.42c0 25.83 26.93 46.77 60.15 46.77h439s-27.41-9.81-27.41-61.73z"
                    transform="translate(255.8 355)"
                  ></path>
                  <path
                    fill="#DAEEF7"
                    d="M319.16 55.92v29.42c0 3.87.62 7.62 1.75 11.22 14.81-28.17 43.18-55.3 97.2-64.03 126.12-20.37 400.2-23.39 400.2-23.39h-439c-33.22 0-60.15 20.94-60.15 46.77v.01z"
                    style={{ mixBlendMode: 'multiply' }}
                    transform="translate(255.8 355)"
                  ></path>
                  <path
                    fill="#96A65F"
                    d="M133.37 87.54V53.72c0-29.41 30.66-53.25 68.48-53.25h-68.64c-37.82 0-68.48 23.84-68.48 53.25v33.82c0 29.41 30.66 53.25 68.48 53.25l67.73-.02c-37.4-.38-67.57-24.06-67.57-53.23z"
                    opacity="0.5"
                    transform="translate(255.8 355)"
                  ></path>
                </g>
                <path
                  id="Path"
                  fill="#3797DB"
                  d="M1083.89 678.04h36.17c-5.92-3.16-13.32-5.06-21.38-5.06H192.46c-47.41 0-85.85 26.64-85.85 59.49v37.79c0 32.86 38.44 59.49 85.85 59.49l896.09-.21-4.66-151.5z"
                ></path>
                <path
                  id="Path"
                  fill="#3B77BE"
                  d="M569.41 673.12c-45.34 0-82.1 25.47-82.1 56.89v42.71c0 31.42 36.76 56.89 82.1 56.89h564.15v-9.55h-2.33l-143.42-9.41-28.99-78.81s55.95-43.55 59.6-44.67c3.65-1.12 112.81-4.53 112.81-4.53l2.33-.02v-9.5H569.41z"
                ></path>
                <path
                  id="Path"
                  fill="#D1EAF2"
                  d="M1096.97 751.09c0-46.08 34.36-68.41 34.36-68.41H580.96c-41.65 0-75.41 23.4-75.41 52.26v32.87c0 28.86 33.76 52.26 75.41 52.26h550.37s-34.36-10.96-34.36-68.98z"
                ></path>
                <path
                  id="Path"
                  fill="#DAEEF7"
                  d="M505.55 734.93v32.87c0 4.32.77 8.52 2.2 12.53 18.56-31.47 54.14-61.79 121.86-71.54 158.12-22.76 501.71-26.13 501.71-26.13H580.95c-41.65 0-75.41 23.4-75.41 52.26l.01.01z"
                  style={{ mixBlendMode: 'multiply' }}
                ></path>
                <path
                  id="Path"
                  fill="#62BDF3"
                  d="M7.03 828.68h1314.25c3.88 0 7.03 3.15 7.03 7.03v3.23c0 3.88-3.15 7.03-7.03 7.03H7.03c-3.88 0-7.03-3.15-7.03-7.03v-3.23c0-3.88 3.15-7.03 7.03-7.03z"
                  transform="rotate(-180 664.155 837.325)"
                ></path>
                <path id="Path" fill="#3797DB" d="M1336.37 258.88l-253.73-145.12-34.29 67.93 259.62 137.35z"></path>
              </g>
              <g id="intro_stars" transform="translate(.78 44.74)">
                <path
                  id="Path"
                  fill="#7772D6"
                  d="M1532.7 174.91s2 19.73 17.57 21.1c-15.57 1.37-17.57 21.1-17.57 21.1s-2-19.73-17.57-21.1c15.57-1.37 17.57-21.1 17.57-21.1z"
                ></path>
                <path
                  id="Path"
                  fill="#FF5B7E"
                  d="M1641.81 894.86s3 29.6 26.36 31.65c-23.36 2.05-26.36 31.65-26.36 31.65s-3-29.6-26.36-31.65c23.36-2.05 26.36-31.65 26.36-31.65z"
                ></path>
                <path
                  id="Path"
                  fill="#9ED16F"
                  d="M1801.45 340.2s2.67 26.28 23.41 28.1c-20.74 1.82-23.41 28.1-23.41 28.1s-2.67-26.28-23.41-28.1c20.74-1.82 23.41-28.1 23.41-28.1zM333.97 1011.41s1.79 17.69 15.75 18.91c-13.96 1.22-15.75 18.91-15.75 18.91s-1.79-17.69-15.75-18.91c13.96-1.22 15.75-18.91 15.75-18.91z"
                ></path>
                <path
                  id="Path"
                  fill="#3797DB"
                  d="M1475.37 514.27s1.79 17.65 15.72 18.87c-13.93 1.22-15.72 18.87-15.72 18.87s-1.79-17.65-15.72-18.87c13.93-1.22 15.72-18.87 15.72-18.87zM1232.9 51.01s1.79 17.65 15.72 18.87c-13.93 1.22-15.72 18.87-15.72 18.87s-1.79-17.65-15.72-18.87c13.93-1.22 15.72-18.87 15.72-18.87zm-853.52 292.6s2.34 23.09 20.57 24.69c-18.23 1.6-20.57 24.69-20.57 24.69s-2.34-23.1-20.57-24.69c18.22-1.6 20.57-24.69 20.57-24.69zM271 701.92s3.43 33.84 30.13 36.18c-26.7 2.34-30.13 36.18-30.13 36.18s-3.43-33.84-30.13-36.18c26.7-2.34 30.13-36.18 30.13-36.18zm904.09-389.83s2.67 26.28 23.41 28.1c-20.74 1.82-23.41 28.1-23.41 28.1s-2.67-26.28-23.41-28.1c20.74-1.82 23.41-28.1 23.41-28.1z"
                ></path>
                <path
                  id="Path"
                  fill="#7772D6"
                  d="M1778.05 681.89s2.67 26.28 23.41 28.1c-20.74 1.82-23.41 28.1-23.41 28.1s-2.67-26.28-23.41-28.1c20.74-1.82 23.41-28.1 23.41-28.1zM649.15 230.3s1.9 18.72 16.67 20.02c-14.78 1.3-16.67 20.02-16.67 20.02s-1.9-18.72-16.67-20.02c14.78-1.29 16.67-20.02 16.67-20.02zm-89.2 345.25s3.04 29.99 26.7 32.06c-23.66 2.08-26.7 32.06-26.7 32.06s-3.04-29.99-26.7-32.06c23.66-2.07 26.7-32.06 26.7-32.06z"
                ></path>
                <path
                  id="Path"
                  fill="#9ED16F"
                  d="M18.23 463.71s2.08 23.79 18.24 25.43c-16.16 1.65-18.24 25.43-18.24 25.43S16.15 490.78 0 489.14c16.16-1.65 18.23-25.43 18.23-25.43zM684.31 0s2.08 23.86 18.29 25.51c-16.21 1.65-18.29 25.51-18.29 25.51s-2.08-23.86-18.29-25.51C682.22 23.86 684.31 0 684.31 0z"
                ></path>
              </g>
              <g id="intro_person" transform="translate(801 197)">
                <path
                  id="Path"
                  fill="#DAEEF7"
                  d="M140.41 326.55l40.94 3.29-13.21 33.12-20.66-1.66-8.6 27.61c-2.1-.96-3.64-1.68-3.95-1.89-.63-.42-2.91-14.12-4.92-27.05l10.41-33.41-.01-.01z"
                  style={{ mixBlendMode: 'multiply' }}
                ></path>
                <path
                  id="Path"
                  fill="#45258F"
                  d="M163.77 631.09v14.43c0 1.19.96 2.16 2.15 2.16h33.69c2.44 0 3.37-3.19 1.31-4.51l-18.9-12.08s-7.41 5.44-18.25 0z"
                ></path>
                <path
                  id="Path"
                  fill="#EFAC7F"
                  d="M97.63 19.38c-.49.19-1.01-.24-.9-.75.84-4.01 3.42-16.63 2.45-17.08-1.15-.53-3.73 10.7-4.9 10.83-1.17.14 1.62-10.75.29-11.12-1.33-.37-3.54 9.64-4.15 9.68-.61.04.48-9.78-.17-10.46-.65-.68-3.09 9.66-3.82 9.91-.73.26-1.42-7.44-2.09-7.45-.67-.02-1.11-.14-4.52 17.63 0 0-.2 6.51-1.35 8.04l2.56 5.23 7.95.26s17.27-15.62 17.13-16.58c-.13-.91-.9-1.12-8.48 1.85v.01zm81.81 3.93c.35.4 1 .25 1.14-.26 1.08-3.95 4.54-16.37 5.61-16.32 1.27.05-1.55 11.22-.57 11.88.98.66 3.46-10.31 4.81-10.03 1.35.28-1.25 10.2-.72 10.51.53.31 4.03-8.92 4.92-9.24.89-.31-1.65 10-1.12 10.57.53.57 4.65-5.97 5.25-5.68.6.29 1.05.38-4.01 17.75 0 0-2.79 5.89-2.46 7.77l-4.67 3.49-7.2-3.39s-8.25-21.78-7.69-22.56c.53-.74 1.31-.59 6.7 5.51h.01zm-63.17 142.3l.13 30.38-29.51-4.55 4.92-34.16z"
                ></path>
                <path
                  id="Path"
                  fill="#D19369"
                  d="M89.54 173l2.27-15.73 24.49 11.21v6c-5.89 1.44-16.93 2.73-26.76-1.48z"
                  opacity="0.6"
                ></path>
                <path
                  id="Path"
                  fill="#45258F"
                  d="M62.95 629.48v15.83c0 1.31-1.06 2.37-2.37 2.37H23.52c-2.68 0-3.7-3.5-1.44-4.94l20.79-13.25s8.16 5.96 20.08 0v-.01zM29.1 360.56c.07-3.39-8.74 6.6-8.57 3.89 3.46-56.21 113.28-9.08 113.28-9.08l26.81 19.67 30.26 260.49s-16.43 4.76-27.24.87L96.62 413.18l-8.24-3.17s-36.65-25.57-59.28-49.45z"
                ></path>
                <g id="Group" transform="translate(19 351)">
                  <path
                    id="Path"
                    fill="#06BFAD"
                    d="M1.4 15.75zm-.09 2.05c-.03.7-.05 1.43-.08 2.17.02-.74.05-1.46.08-2.17zm-.08 2.46zm.3-6.8c-.04.61-.07 1.24-.1 1.88.03-.64.07-1.28.1-1.88z"
                  ></path>
                  <path
                    id="Path"
                    fill="#2D185D"
                    d="M1.54.69c22.63 23.88 67.84 58.33 67.84 58.33l8.24 3.17-8.24-3.17L43.9 284.55s-18.16 3.46-28.54-2.16c0 0-15.3-214.67-13.82-281.69V.69z"
                  ></path>
                  <path
                    id="Path"
                    fill="#06BFAD"
                    d="M171.88 284.54s-7.08 2.05-14.99 2.36c7.92-.31 14.99-2.36 14.99-2.36zm-24.07 1.74c.73.15 1.47.27 2.22.37-.75-.09-1.49-.22-2.22-.37zm9.07.62c-1.89.07-3.82.05-5.72-.13 1.9.17 3.83.2 5.72.13z"
                  ></path>
                </g>
                <path
                  id="Path"
                  fill="#2D185D"
                  d="M160.61 375.05l4.32 37.18c-11.43 4.42-39.29 9.15-60.28-36.76-6.62-14.48-8.32-24.9-7.1-32.39 20.1 5.38 36.25 12.3 36.25 12.3l26.81 19.67z"
                ></path>
                <path
                  id="Path"
                  fill="#D1EAF2"
                  d="M59.85 223.3c1.27 0 20.29-43.11 20.29-43.11 16.27 11.41 38.36 3.49 38.36 3.49s88.87 162.62 29.27 187.98c-59.6 25.36-115.81-5.5-115.81-5.5s-12.68-32.97-12.68-34.24c0-1.27 39.31-108.62 40.58-108.62h-.01z"
                ></path>
                <path
                  id="Path"
                  fill="#9ED16F"
                  d="M175.66 40.71s3.85 77.15-3.38 91.3c-23.99 46.92-53.78 51.67-53.78 51.67s-10.25 164.1-1.8 203.41c0 0 28.11 8.67 69.53-10.78 0 0-31.7-127.22-30.01-143.28 0 0 50.72-45.23 52.41-98.48 1.69-53.26-12.26-100.59-12.26-100.59l-20.71 6.76v-.01zM38.93 381.17s3.17-61.08 17.12-118.98c13.95-57.9 24.09-82 24.09-82-18.7-20.6-15.6-39.23-13.95-63.4 1.9-27.9 29.16-83.69 29.16-83.69L76.96 21.05c-88.12 105.88-39.31 190.2-39.31 190.2S4.68 327.91.24 363.41c0 0 17.54 10.14 38.67 17.75l.02.01z"
                ></path>
                <path
                  id="Path"
                  fill="#96A65F"
                  d="M155.36 232.85c-1.06-.75-2.32-1.9-3.54-3.37-3.74-4.51-5.67-9.59-7.01-15.26.81 2.42 2.98 9.46 7.54 14.82 2.05 2.33 3.13 3.13 4.12 3.76-.16.15-.25.23-.25.23l-.03.33c-1.17.24-3.23.32-6.7-1.57-2.15-1.13-3.86-2.53-5.63-4.04 1.16.83 3.57 2.57 5.84 3.64 1.58.78 3.9 1.59 5.66 1.47v-.01zM38.33 210.87c1.51-.96 2.82-2.33 4.03-3.71 3.74-4.39 5.79-9.58 7.54-14.82-1.33 5.65-3.26 10.73-7.01 15.26-1.22 1.46-2.46 2.6-3.54 3.37.83.06 1.82-.1 2.48-.29 1.09-.27 2.16-.68 3.18-1.18 2.12-.99 4.21-2.48 5.84-3.64-1.58 1.34-3.38 2.86-5.64 4.04-1.02.56-2.1 1.02-3.24 1.36-1.15.29-2.31.54-3.61.18l-.67-.18.64-.4v.01z"
                ></path>
                <path id="Path" fill="#5E2E0D" d="M73.94 103.62l-1.79 20.84 3.3 2.08 6.09-25.39z"></path>
                <path
                  id="Path"
                  fill="#D19369"
                  d="M78.37 122.31c.91 4.72-1.42 9.14-5.2 9.86-3.78.73-7.58-2.52-8.48-7.24-.91-4.72 1.42-9.14 5.2-9.86 3.78-.73 7.58 2.52 8.48 7.24z"
                ></path>
                <path
                  id="Path"
                  fill="#EFAC7F"
                  d="M72.96 141.58c-.97-6.04-.83-12.21-.29-17.51.12-1.14.16-2.28.19-3.42.11-4.62 1.07-9.34 3.09-14 6.5-14.97 22.37-24.06 38.57-22.05 21.82 2.71 36.2 23.24 32 44.23 2.54-.81 5.16-.59 7.21.87 3.94 2.8 4.3 9.06.8 13.99s-9.53 6.65-13.47 3.85c-.55-.39-1.02-.85-1.43-1.36-5.39 17.49-22.79 28.35-40.28 24.55-13.28-2.89-24.24-13.9-26.4-29.15h.01z"
                ></path>
                <path
                  id="Path"
                  fill="#333"
                  d="M113.31 124.91c-.86 2.16.7 4.48 3.06 4.47 1.29 0 2.48-.82 2.95-2.02.86-2.16-.7-4.49-3.07-4.48-1.29 0-2.48.83-2.95 2.03h.01zm-27.64-4.14c-.86 2.16.7 4.49 3.07 4.47 1.29 0 2.48-.83 2.95-2.03.86-2.16-.7-4.48-3.07-4.47-1.29 0-2.48.83-2.95 2.03z"
                ></path>
                <path
                  id="Path"
                  fill="#D19369"
                  d="M109.01 147.62c-1.71.86-3.64 1.49-5.22 1.79-1.8.35-3.64.46-5.43.21-1.8-.24-3.53-.87-5.11-1.78-1.57-.93-2.97-2.15-4.24-3.51 1.29 1.82 2.46 2.88 3.98 3.93 1.57 1.03 3.24 1.69 5.28 2 1.89.25 3.81.11 5.63-.37 1.82-.48 3.57-1.21 5.11-2.27z"
                ></path>
                <path
                  id="Path"
                  fill="#5E2E0D"
                  d="M110.68 118.32c1.69-.3 3.29-.49 4.85-.52 1.56-.03 3.07.14 4.51.54 1.43.41 2.85.94 4.15 1.8 1.77 1.01 3.05 2.25 3.98 2.85-.59-1.5-1.55-3.28-2.85-4.32-1.26-1.26-2.93-2.13-4.68-2.61-1.76-.48-3.62-.49-5.35-.09-1.72.42-3.35 1.16-4.62 2.35h.01zm-12.91-2.13c-.82-1.53-2.13-2.76-3.62-3.71a11.04 11.04 0 00-5.09-1.63c-1.81-.1-3.67.18-5.26.97-1.57.57-3.05 1.96-4.08 3.18 1.16-.3 2.62-1.03 4.68-1.43 1.51-.39 3.02-.45 4.51-.38 1.49.09 2.98.41 4.45.94 1.47.53 2.92 1.22 4.43 2.05l-.02.01z"
                ></path>
                <path
                  id="Path"
                  fill="#D19369"
                  d="M103.4 120.29c-1.52 1.43-2.92 2.98-4.23 4.59-1.32 1.61-2.55 3.3-3.64 5.11-.62 1.08-1.41 2.53-1.59 3.76-.11.68-.05 1.34 1.09 2.46.75.79 1.59 1.44 2.45 2.03.87.58 1.77 1.12 2.72 1.56-1.58-1.35-3.17-2.7-4.42-4.25-.2-.24-.58-.8-.71-1.11-.07-.23.05-.73.22-1.16.34-.89.83-1.79 1.35-2.65 1.03-1.75 2.2-3.45 3.34-5.17 1.16-1.71 2.32-3.42 3.44-5.18l-.02.01z"
                ></path>
                <path
                  id="Path"
                  fill="#5E2E0D"
                  d="M77.82 81.35s-10.95-2.11 2.54-8.72c13.49-6.61 46.56-10.46 63.85 4.35 0 0 5.81-7.46 11.04-4.18 5.23 3.28-5.44 6.9-5.44 6.9s7.3 1.41 8.71 5.46c1.04 3-3.42 2.11-5.82 1.44 3.97 1.63 15.64 12.66.48 42.76-2.68-1.26-20.02-25.99-20.02-25.99s-34.86 11.45-28.51 1.95c0 0-40.63 10.22-30.78-8.2 0 0-14.98-2.25 3.96-15.77h-.01z"
                ></path>
                <path
                  id="Path"
                  fill="#5E2E0D"
                  d="M153.17 129.36s-2.63-2.01-6.65-.52c-4.02 1.49-6.57 10.51-6.57 10.51s-13.16 10.77-6.74-36.18c6.3-46.13 15.03 7.68 15.03 7.68l4.93 18.52v-.01z"
                ></path>
              </g>
              <g id="intro_trophy" transform="translate(872)">
                <path
                  id="Path"
                  fill="#3797DB"
                  d="M101.37 181.98l-60.15-4.29c-3.04-.22-5.34-2.86-5.12-5.9.22-3.04 2.86-5.34 5.9-5.12l60.15 4.28c3.04.22 5.34 2.86 5.12 5.9-.22 3.04-2.86 5.34-5.9 5.12v.01zM84.04.84l21.03 51.53 54.36 11.9-42.51 35.91 5.49 55.39-47.3-29.34-50.97 22.33 13.28-54.04L.43 52.94l55.5-4.07z"
                ></path>
                <g id="intro_tropy_highlights" fill="#3B77BE">
                  <path id="Path" d="M84.04.84l-5.38 75.62 26.41-24.09z"></path>
                  <path
                    id="Path"
                    d="M159.43 64.27L78.66 76.46l38.26 23.72zM78.66 76.46L55.93 48.87.43 52.94zm-54.52 72.1l54.52-72.1-41.24 18.06z"
                  ></path>
                  <path id="Path" d="M122.41 155.57L78.66 76.46l-3.55 49.77z"></path>
                </g>
                <path
                  id="Rectangle"
                  fill="#7772D6"
                  d="M24.219 181.058h91.62v32.2h-91.62z"
                  transform="rotate(4.08 70.03 197.158)"
                ></path>
                <path
                  id="Rectangle"
                  fill="#CDD6E0"
                  style={{ mixBlendMode: 'multiply' }}
                  d="M67.329 165.586h3.52v91.62h-3.52z"
                  transform="rotate(-85.92 69.089 211.396)"
                ></path>
                <path
                  id="Rectangle"
                  fill="#CDD6E0"
                  style={{ mixBlendMode: 'multiply' }}
                  d="M69.433 136.071h3.52v91.62h-3.52z"
                  transform="rotate(-85.92 71.193 181.881)"
                ></path>
                <path
                  id="Path"
                  fill="#3B77BE"
                  d="M116.11 185.79l-90.02-6.41c-2.28-.16-4-2.14-3.83-4.42.16-2.28 2.14-4 4.42-3.83l90.02 6.41c2.28.16 4 2.14 3.83 4.42-.16 2.28-2.14 4-4.42 3.83z"
                ></path>
                <path
                  id="Path"
                  fill="#7772D6"
                  d="M115.8 223.92l-96.05-6.84c-.61-.04-1.07-.58-1.03-1.19l.43-6.03c.04-.61.58-1.07 1.19-1.03l96.05 6.84c.61.04 1.08.58 1.03 1.19l-.43 6.03c-.04.61-.58 1.07-1.19 1.03z"
                ></path>
                <path
                  id="Path"
                  fill="#3797DB"
                  d="M86.53 205.86l-34.75-2.48a7.839 7.839 0 01-7.26-8.38 7.839 7.839 0 018.38-7.26l34.75 2.48c4.32.31 7.57 4.06 7.26 8.38a7.839 7.839 0 01-8.38 7.26z"
                ></path>
                <path
                  id="Path"
                  fill="#D9D9D9"
                  d="M51.96 200.77a5.225 5.225 0 01-4.75-6.23c.51-2.63 3.05-4.37 5.72-4.18l34.31 2.44c2.67.19 4.94 2.27 5.07 4.95.16 3.19-2.5 5.71-5.59 5.49l-34.75-2.48-.01.01z"
                  style={{ mixBlendMode: 'multiply' }}
                ></path>
                <path
                  id="Path"
                  fill="#3797DB"
                  d="M81.51 172.84l-19.33-1.38.13-1.79c.52-7.29 1.33-22.43 12.81-43.44 8.72 24.03 7.04 37.53 6.52 44.82l-.13 1.79z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
