import { Container } from '@mantine/core';
// eslint-disable-next-line import/no-internal-modules
import React, { FunctionComponent } from 'react';

import { HomepageHeight } from '@components/Default/Homepage/HomepageHeight';
import { HomepageReadPages } from '@components/Default/Homepage/HomepageReadPages';
import { HomepageSchools } from '@components/Default/Homepage/HomepageSchools';
import { HomepageStudents } from '@components/Default/Homepage/HomepageStudents';

export interface IStats {
  readPages: {
    total: number;
    currentYear?: number;
  };
  schools: {
    total: number;
  };
  students: {
    total: number;
    active: number;
  };
  latestBooks: Array<{
    isbn: string;
    catalogId: string;
    title: string;
  }>;
}

interface IProps {
  stats: IStats;
}

export const HomepageStats: FunctionComponent<IProps> = ({ stats }) => {
  return (
    <Container size="md" sx={{ overflow: 'hidden' }}>
      <HomepageHeight mb={50} readPages={stats.readPages.total} readPagesCurrentYear={stats.readPages.currentYear} />
      <HomepageReadPages mb={50} readPages={stats.readPages.total} readPagesCurrentYear={stats.readPages.currentYear} />
      <HomepageSchools schoolsCount={stats.schools.total} />
      <HomepageStudents studentsCount={stats.students.active} />
    </Container>
  );
};
