import { Box, Container, createStyles, Group, SimpleGrid, Stack, Text } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { chunk } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';

import { Trans } from '@app/i18n';
import { HomepageLatestBooksItem } from '@components/Default/Homepage/HomepageLatestBooksItem';
import { IStats } from '@components/Default/Homepage/HomepageStats';

const useStyles = createStyles((theme) => ({
  column: {
    width: '25%',
    flexShrink: 0,

    [theme.fn.largerThan('md')]: {
      width: '15%',
    },

    [theme.fn.largerThan('lg')]: {
      width: '12.5%',
    },

    [theme.fn.largerThan('xl')]: {
      width: '10%',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      transform: 'translateX(-12.5%)',

      [theme.fn.largerThan('md')]: {
        transform: 'translateX(-7.5%)',
      },

      [theme.fn.largerThan('lg')]: {
        transform: 'translateX(-6%)',
      },

      [theme.fn.largerThan('xl')]: {
        transform: 'translateX(-5%)',
      },
    },
  },
  headline: {
    lineHeight: 1.1,
    fontWeight: 900,
    fontSize: 50,
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
    zIndex: 2,
    textAlign: 'center',

    [theme.fn.largerThan('sm')]: {
      fontSize: 70,
    },

    [theme.fn.largerThan('xl')]: {
      fontSize: 100,
    },
  },
}));

interface IProps {
  latestBooks: IStats['latestBooks'];
}

export const HomepageLatestBooksList: FunctionComponent<IProps> = ({ latestBooks }) => {
  const { classes, cx } = useStyles();
  const chunkSize = 15;
  const chunks = chunk(latestBooks, chunkSize);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    gsap.registerPlugin(ScrollTrigger);

    const s = gsap.utils.selector('#latestBooks');

    /*
    gsap.fromTo(
      '#latestBooks__headline',
      {
        yPercent: -200,
      },
      {
        yPercent: 200,
        ease: 'none',
        scrollTrigger: {
          id: 'text',
          trigger: '#latestBooks',
          start: 'top center',
          end: 'bottom center',
          scrub: true,
          // markers: true,
        },
      }
    );

    s('.latestBooks__group').forEach((group) => {
      gsap.from(group.querySelectorAll('.latestBooks__book'), {
        scale: 0.9,
        opacity: 0,
        duration: 0.3,
        stagger: 0.1,
        scrollTrigger: {
          trigger: '#latestBooks',
          start: 'top center',
          end: 'bottom center',
          // markers: true,
        },
      });
    });
    */

    gsap.to('#latestBooks__slider', {
      x: () =>
        `-=${
          document.querySelector('#latestBooks .latestBooks__book').clientWidth * chunkSize -
          document.documentElement.clientWidth
        }`,
      ease: 'none',
      duration: 20,
      scrollTrigger: {
        id: 'books',
        trigger: '#latestBooks',
        start: 'top center',
        end: 'bottom center',
        // toggleActions: 'play pause resume pause',
        // end: 'bottom top',
        scrub: true,
        // markers: true,
      },
    });
  });

  return (
    <Box mt={100}>
      <SimpleGrid cols={1}>
        <Container size="md" sx={{ gridArea: '1/1', zIndex: 3, width: '100%' }}>
          <Stack sx={{ height: '100%' }} justify="center" align="center">
            <Text id="latestBooks__headline" className={classes.headline}>
              <Trans ns="page_homepage" i18nKey="currentBooks.headline" />
            </Text>
          </Stack>
        </Container>
        <Box sx={{ zIndex: 2, gridArea: '1/1', background: 'rgba(255 255 255 / 0.5)' }}></Box>
        <Box id="latestBooks" sx={{ overflow: 'hidden', gridArea: '1/1', zIndex: 1 }}>
          <Stack id="latestBooks__slider">
            {chunks.map((chunk, index) => (
              <Group key={index} noWrap={true} className={cx('latestBooks__group', classes.row)}>
                {chunk.map(({ isbn, catalogId, title }, index) => (
                  <Box key={`${isbn}-${index}`} className={classes.column}>
                    <HomepageLatestBooksItem isbn={isbn} catalogId={catalogId} title={title} />
                  </Box>
                ))}
              </Group>
            ))}
          </Stack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
