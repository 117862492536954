import { useQuery } from '@tanstack/react-query';
import { GetServerSideProps, GetStaticProps, NextPage } from 'next';
import Head from 'next/head';

import { HomepageIntroText } from '@app/components/Default/Homepage/HomepageIntroText';
import { Hero } from '@components/Default/Homepage/Hero';
import { HomepageLatestBooksList } from '@components/Default/Homepage/HomepageLatestBooksList';
import { HomepageNextStudent } from '@components/Default/Homepage/HomepageNextStudent';
import { HomepageStats, IStats } from '@components/Default/Homepage/HomepageStats';

interface IProps {
  stats?: IStats;
}

const Welcome: NextPage<IProps> = ({ stats }) => {
  /*/
  const { data: stats } = useQuery<IStats>(['stats'], async () => {
    const res = await fetch('/stats', {credentials: 'same-origin'});
    return await res.json();
  });
  /**/

  return (
    <>
      <Head>
        <title>NEXT LEVEL</title>
      </Head>

      <div>
        <Hero />

        <HomepageIntroText />

        {stats && <HomepageStats stats={stats} />}

        <HomepageNextStudent />

        {stats && <HomepageLatestBooksList latestBooks={stats.latestBooks} />}
      </div>
    </>
  );
};

/**/
export const getServerSideProps: GetServerSideProps<IProps> = async ({ res }) => {
  let stats = null;
  const response = await fetch(`${process.env.NEXT_PUBLIC_ENTRYPOINT}/stats`);

  if (response.ok) {
    stats = await response.json();
  }

  res.setHeader(
    'Cache-Control',
    `public, s-maxage=${60 * 60 * 24}` //, stale-while-revalidate=59
  );

  return {
    props: {
      stats,
    },
  };
};
/**/
/*/
export const getStaticProps: GetStaticProps = async () => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_ENTRYPOINT}/stats`);
  const stats = await res.json();

  return {
    props: {
      stats,
    },
  };
};
/**/
export default Welcome;
