import { Box, BoxProps, createStyles, Stack, Text } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { FunctionComponent, useEffect } from 'react';

import { Trans, useTranslation } from '@app/i18n';
import { StackHeightSvg } from '@components/Default/Homepage/StackHeightSvg';
import { getStackHeightInMM } from '@utilities/bookStack';

const useStyles = createStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',

    '> *': {
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
    },

    '> svg': {
      maxWidth: '100%',
      height: 'auto',
      transform: 'translateX(-12%)',
      [theme.fn.largerThan('sm')]: {
        transform: 'none',
      },
    },
  },
  readPagesWrapper: {
    zIndex: 1,
  },
  readPages: {
    lineHeight: 1,
    fontWeight: 900,
    fontSize: 35,
    [theme.fn.largerThan('sm')]: {
      fontSize: 65,
    },
  },
  readPagesSubtext: {
    lineHeight: 1,
    fontWeight: 700,
    fontSize: 20,
    [theme.fn.largerThan('sm')]: {
      fontSize: 35,
    },
  },
  readPagesCurrent: {
    lineHeight: 1,
    fontWeight: 900,
    fontSize: 25,
    [theme.fn.largerThan('sm')]: {
      fontSize: 45,
    },
  },
  readPagesCurrentSubtext: {
    lineHeight: 1,
    fontWeight: 700,
    fontSize: 16,
    [theme.fn.largerThan('sm')]: {
      fontSize: 24,
    },
  },
}));

const formatter = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IProps extends BoxProps {
  readPages: number;
  readPagesCurrentYear?: number;
}

export const HomepageHeight: FunctionComponent<IProps> = ({ readPages, readPagesCurrentYear, ...boxProps }) => {
  const additionalText = readPagesCurrentYear && readPagesCurrentYear >= 250000;
  const { classes } = useStyles();
  const [t] = useTranslation('page_homepage');

  const stackHeight = getStackHeightInMM(readPages) / 1000;
  const stackHeightCurrent = getStackHeightInMM(readPagesCurrentYear || 0) / 1000;

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    gsap.registerPlugin(ScrollTrigger);

    const t = gsap.timeline({
      scrollTrigger: {
        trigger: '#stackHeight',
        start: 'top center',
        end: 'bottom center',
        // markers: true,
        // scrub: true,
      },
    });
    t.from('#rocket', {
      yPercent: 70,
      duration: 2,
      ease: 'power2.in',
    })
      .from(
        '#stackHeight-text',
        {
          xPercent: 100,
          duration: 0.3,
        },
        0
      )
      .fromTo(
        '#stackHeightValue',
        {
          textContent: '0.00',
        },
        {
          textContent: stackHeight.toFixed(2).toString(),
          ease: 'power3.out',
          // ease: 'none',
          duration: 2,
          snap: { textContent: 0.01 },
          onUpdate: function () {
            const targets = this.targets();
            if (targets && targets[0]) {
              targets[0].innerHTML = formatter.format(this.targets()[0].textContent);
            }
          },
        },
        0
      )
      .fromTo(
        '#stackHeightCurrentValue',
        {
          textContent: '0.00',
        },
        {
          textContent: stackHeightCurrent.toFixed(2).toString(),
          ease: 'power3.out',
          // ease: 'none',
          duration: 2,
          snap: { textContent: 0.01 },
          onUpdate: function () {
            const targets = this.targets();
            if (targets && targets[0]) {
              targets[0].innerHTML = formatter.format(this.targets()[0].textContent);
            }
          },
        },
        '<+=0.5'
      );
  });

  return (
    <Box id="stackHeight" className={classes.grid} {...boxProps}>
      <Stack id="stackHeight-text" spacing={0} className={classes.readPagesWrapper} justify="center" align="end">
        <Text className={classes.readPages}>
          <Trans
            ns="page_homepage"
            i18nKey="stats.stackHeight.unit"
            values={{ height: stackHeight.toFixed(2).toString() }}
            components={{
              number: <span id="stackHeightValue" />,
            }}
          />
        </Text>
        <Text color="dimmed" className={classes.readPagesSubtext}>
          {t('stats.stackHeight.description')}
        </Text>
        {additionalText && (
          <>
            <Text mt={20} className={classes.readPagesCurrent}>
              <Trans
                ns="page_homepage"
                i18nKey="stats.stackHeight.unit"
                values={{ height: stackHeightCurrent.toFixed(2).toString() }}
                components={{
                  number: <span id="stackHeightCurrentValue" />,
                }}
              />
            </Text>
            <Text color="dimmed" className={classes.readPagesCurrentSubtext}>
              {t('stats.stackHeight.currentYear')}
            </Text>
          </>
        )}
      </Stack>
      <StackHeightSvg />
    </Box>
  );
};
