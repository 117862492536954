import { Box, Button, Container, createStyles, Image, SimpleGrid, Stack, Text } from '@mantine/core';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '@app/i18n';

const useStyles = createStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      gridTemplateRows: 'minmax(0, auto) minmax(0,1fr)',
    },
  },
  headline: {
    maxWidth: '10ch',
    lineHeight: 1.1,
    fontWeight: 900,
    fontSize: 35,
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
    zIndex: 2,

    [theme.fn.largerThan('sm')]: {
      fontSize: 70,
    },
  },
  svg: {
    gridColumn: '1 / 2',
    gridRow: '1 / 3',
    zIndex: 1,
    marginTop: 30,

    [theme.fn.largerThan('sm')]: {
      marginTop: 0,
    },
  },
  button: {
    justifySelf: 'center',

    [theme.fn.largerThan('sm')]: {
      gridColumn: '1 / 2',
      gridRow: '2 / 3',
      zIndex: 2,
      justifySelf: 'start',
    },
  },
}));

export const HomepageNextStudent: FunctionComponent = () => {
  const { classes } = useStyles();
  const [t] = useTranslation('page_homepage');

  return (
    <Container size="md" mt={100} sx={{ overflow: 'hidden' }}>
      <Box className={classes.grid}>
        <Text className={classes.headline}>{t('nextStudent.headline')}</Text>
        <Image
          className={classes.svg}
          src="/static/statistics/next_student.svg"
          alt="Illustration eines lesenden Schülers"
        />
        <Link href="/account/register" passHref={true}>
          <Button radius="xl" size="lg" mt="xl" className={classes.button}>
            {t('nextStudent.cta')}
          </Button>
        </Link>
      </Box>
    </Container>
  );
};
