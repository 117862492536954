import { Container, Group, Text } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { Trans, useTranslation } from '@app/i18n';

export const HomepageIntroText: FunctionComponent = () => {
  const [t] = useTranslation('page_homepage');

  return (
    <Container py={75}>
      <Group position="center">
        <Text size="xl" weight={600} align="center" sx={{ maxWidth: 600 }}>
          <Trans ns="page_homepage" i18nKey="intro.text" />
        </Text>
      </Group>
    </Container>
  );
};
