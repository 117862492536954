import { createStyles, Group, Image, Stack, Text } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { FunctionComponent, useEffect } from 'react';

import { Trans, useTranslation } from '@app/i18n';

const useStyles = createStyles((theme) => ({
  wrapper: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column-reverse',
      marginTop: 50,
    },
  },
  schools: {
    fontSize: 50,
    lineHeight: 1,
    fontWeight: 900,
  },
  schoolsSubtext: {
    fontSize: 25,
    lineHeight: 1,
  },
}));

interface IProps {
  schoolsCount: number;
}

export const HomepageSchools: FunctionComponent<IProps> = ({ schoolsCount }) => {
  const { classes } = useStyles();
  const [t] = useTranslation('page_homepage');

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    gsap.registerPlugin(ScrollTrigger);

    gsap.from('#schools > *', {
      yPercent: 20,
      opacity: 0,
      duration: 1,
      stagger: 0.25,
      scrollTrigger: {
        id: 'schools',
        trigger: '#schools',
        start: 'top center+=10%',
        end: 'bottom center+=10%',
        // markers: true,
        // scrub: true,
      },
    });
  });
  return (
    <Group id="schools" position="center" align="center" className={classes.wrapper}>
      <Image src="/static/statistics/school.svg" sx={{ maxWidth: 376 }} alt="Illustration einer Schule" />
      <Stack spacing={0} align="end">
        <Text className={classes.schools}>{t('stats.schools.unit', { count: schoolsCount })}</Text>
        <Text color="dimmed" align="right" className={classes.schoolsSubtext}>
          <Trans ns="page_homepage" i18nKey="stats.schools.description" />
        </Text>
      </Stack>
    </Group>
  );
};
