import { createStyles, Group, Image, Stack, Text } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { FunctionComponent, useEffect } from 'react';

import { useTranslation } from '@app/i18n';

const useStyles = createStyles((theme) => ({
  students: {
    fontSize: 30,
    lineHeight: 1,
    fontWeight: 900,
    marginTop: 30,

    [theme.fn.largerThan('xs')]: {
      fontSize: 55,
      marginTop: 0,
    },
  },
  studentsSubtext: {
    fontSize: 25,
    lineHeight: 1,
  },
}));

interface IProps {
  studentsCount: number;
}

export const HomepageStudents: FunctionComponent<IProps> = ({ studentsCount }) => {
  const { classes } = useStyles();
  const [t] = useTranslation('page_homepage');

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    gsap.registerPlugin(ScrollTrigger);

    gsap.from('#students > *', {
      yPercent: 20,
      opacity: 0,
      duration: 1,
      stagger: 0.25,
      scrollTrigger: {
        id: 'students',
        trigger: '#students',
        start: 'top center+=10%',
        end: 'bottom center+=10%',
        // markers: true,
        // scrub: true,
      },
    });
  });

  return (
    <Group id="students" position="center" mt="xl">
      <Stack spacing={0}>
        <Text className={classes.students}>{t('stats.students.unit', { count: studentsCount })}</Text>
        <Text color="dimmed" className={classes.studentsSubtext}>
          {t('stats.students.description')}
        </Text>
      </Stack>
      <Image src="/static/statistics/students.png" sx={{ maxWidth: 300 }} alt="Illustration von Schülern" />
    </Group>
  );
};
