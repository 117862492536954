import { Box, BoxProps, createStyles, Stack, Text } from '@mantine/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { FunctionComponent, useEffect } from 'react';

import { useTranslation } from '@app/i18n';
import { BooksSvg } from '@components/Default/Homepage/BookSvg';

const useStyles = createStyles((theme, { additionalText }: { additionalText: boolean }) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',

    '> svg': {
      maxWidth: '100%',
      height: 'auto',
      transform: 'translateX(-12%)',
      [theme.fn.largerThan('sm')]: {
        transform: 'none',
      },
    },

    [theme.fn.smallerThan('sm')]: {
      rowGap: additionalText ? theme.spacing.xs : 0,
      '> *': {
        gridColumn: '1 / 2',
        gridRow: '1 / 2',
      },
      '> *:nth-child(n+2)': {
        gridRow: additionalText ? '2 / 3' : '1 / 2',
      },
    },
    [theme.fn.largerThan('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  text: {
    alignSelf: 'center',
    zIndex: 1,
    [theme.fn.smallerThan('sm')]: {
      placeSelf: 'start',
      zIndex: 1,
    },
    [theme.fn.largerThan('sm')]: {
      gridRow: additionalText ? 'span 1' : 'span 2',
    },
  },
  svg: {
    [theme.fn.smallerThan('sm')]: {
      placeSelf: 'end',
      maxWidth: '75%',
      marginTop: additionalText ? 25 : 60,

      '> svg': {
        maxWidth: '100%',
        height: 'auto',
      },
    },
    [theme.fn.largerThan('sm')]: {
      gridRow: '1 / 3',
      gridColumn: '2 / 3',
    },
  },
  readPages: {
    lineHeight: 1,
    fontWeight: 900,
    fontSize: 60,
    [theme.fn.largerThan('sm')]: {
      fontSize: 85,
    },
  },
  readPagesCurrent: {
    lineHeight: 1,
    fontWeight: 900,
    fontSize: 30,
    [theme.fn.largerThan('sm')]: {
      fontSize: 50,
    },
  },
  readPagesSubtext: {
    lineHeight: 1,
    fontWeight: 700,
    fontSize: 24,
    [theme.fn.largerThan('sm')]: {
      fontSize: 35,
    },
  },
  readPagesCurrentSubtext: {
    lineHeight: 1,
    fontWeight: 700,
    fontSize: 20,
    [theme.fn.largerThan('sm')]: {
      fontSize: 30,
    },
  },
  readPagesSubSubtext: {
    fontSize: 25,
    lineHeight: 1,
  },
}));

interface IProps extends BoxProps {
  readPages: number;
  readPagesCurrentYear?: number;
}

const formatter = new Intl.NumberFormat('de-DE');

export const HomepageReadPages: FunctionComponent<IProps> = ({ readPages, readPagesCurrentYear, ...boxProps }) => {
  const additionalText = readPagesCurrentYear && readPagesCurrentYear >= 250000;
  const { classes } = useStyles({ additionalText });
  const [t] = useTranslation('page_homepage');

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    gsap.registerPlugin(ScrollTrigger);

    const t = gsap.timeline({
      scrollTrigger: {
        trigger: '#readPages-stats',
        start: 'top center+=10%',
        end: 'bottom center+=10%',
        // scrub: true,
        // markers: true,
      },
    });

    t.from('#book-svg', {
      yPercent: 20,
      opacity: 0,
      duration: 0.3,
    })
      .from(
        '#readPages-text, #readPages-current-text',
        {
          xPercent: -100,
          opacity: 0,
          duration: 0.3,
        },
        '<'
      )
      .fromTo(
        '#readPages',
        {
          textContent: '100000',
        },
        {
          textContent: readPages.toString(),
          duration: 2,
          ease: 'power3.out',
          // ease: 'none',
          snap: { textContent: 1 },
          onUpdate: function () {
            const targets = this.targets();
            if (targets && targets[0]) {
              targets[0].innerHTML = formatter.format(this.targets()[0].textContent);
            }
          },
        },
        '<'
      )
      .from(
        ['#book1', '#book2', '#book3', '#book4', '#book5', '#openBook'],
        {
          opacity: 0,
          duration: 2.5,
          stagger: 0.25,
        },
        '<'
      );

    if (additionalText) {
      t.fromTo(
        '#readPages-current',
        {
          textContent: '10000',
        },
        {
          textContent: readPagesCurrentYear.toString(),
          duration: 2,
          ease: 'power3.out',
          // ease: 'none',
          snap: { textContent: 1 },
          onUpdate: function () {
            const targets = this.targets();
            if (targets && targets[0]) {
              targets[0].innerHTML = formatter.format(this.targets()[0].textContent);
            }
          },
        },
        '<+=0.5'
      );
    }
  });
  return (
    <>
      <Box id="readPages-stats" className={classes.grid} {...boxProps}>
        <Stack id="readPages-text" spacing={0} className={classes.text}>
          <Text id="readPages" className={classes.readPages}>
            {readPages}
          </Text>
          <Text color="dimmed" className={classes.readPagesSubtext}>
            {t('stats.readPages.description')}
          </Text>
        </Stack>
        {additionalText && (
          <Stack id="readPages-current-text" spacing={0} className={classes.text}>
            <Text id="readPages-current" className={classes.readPagesCurrent}>
              {readPagesCurrentYear}
            </Text>
            <Text color="dimmed" className={classes.readPagesCurrentSubtext}>
              {t('stats.readPages.currentYear', { count: readPagesCurrentYear })}
            </Text>
          </Stack>
        )}
        <div id="book-svg" className={classes.svg}>
          <BooksSvg />
        </div>
      </Box>
    </>
  );
};
